import React from 'react'
import FormTwo from '../components/FormTwo'
import Forms from '../components/forms'
import Partners from '../components/partners'
import Banner from '../components/banner'
import Footer from '../components/footer'
import Feature from '../components/features'

export default function index() {
  return (
    <div>
        <FormTwo/>
        <Partners/>
        <Feature/>
        <Footer/>


    </div>
  )
}
