import React from 'react'
import honest from '../../static/images/icons/honest.png'
import bank from '../../static/images/icons/bank.png'
import ca from '../../static/images/icons/ca.png'
import legal from '../../static/images/icons/legal.png'
import property from '../../static/images/icons/property.png'
import balance from '../../static/images/icons/balance.png'

export default function features() {
  return (

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" style={{fontFamily:"open sans"}}>
      <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
        <div className="p-8  ">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
               <img className=' w-16 h-16'  src={honest}/>
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Honest Advice</h6>
            <p className="mb-2 text-md text-gray-700">
            Personalised strategy to recommend best bank, the loan amount & tenure for you
            </p>
          </div>
        </div>
        <div className="p-8  ">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            {/* </div> */}
            <img className=' w-16 h-16'  src={bank}/>
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Free Escrow Bank Account</h6>
            <p className="mb-2 text-md text-gray-700">
            Keep advance token money safe till property docs verified and loan approved
            </p>
          </div>
        </div>
        <div className="p-8  ">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
            {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
             
             <img className=' w-16 h-16'  src={ca}/>
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Free Chartered Accountant</h6>
            <p className="mb-2 text-md text-gray-700">
            Receive professional guidance on income tax, property tax, stamp duty and more 
            </p>
          </div>
        </div>
        <div className="p-8 ">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            <img className=' w-16 h-16'  src={legal}/>
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Free Legal consultation</h6>
            <p className="mb-2 text-md text-gray-700">
            Understand legal viewpoint & get property documents verified by the best legal mind
            </p>
          </div>
        </div>
        <div className="p-8 ">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            <img className=' w-16 h-16' src={property} />
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Property Registration</h6>
            <p className="mb-2 text-md text-gray-700">
            Undertake full legal process, register sale deed, stamp duty payment & registration.
            </p>
          </div>
        </div>
        <div className="p-8">
          <div className="max-w-md text-center">
            <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16">
              {/* <svg
                className="w-8 h-8 text-deep-purple-accent-400 sm:w-12 sm:h-12"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg> */}
            <img className=' w-16 h-16' src={balance} />
            </div>
            <h6 className="mb-2 text-xl font-semibold leading-5">Balance Transfer</h6>
            <p className="mb-2 text-md text-gray-700">
            Personal recommendation every 6 months to reduce your EMI by switching the bank
            </p>
          </div>
        </div>
      </div>
    </div>
 










  )
}
