import React from 'react'
import Logo from '../../static/images/logo1.png'
import FooterLogo from '../../static/images/footerLogoone.png'

export default function footer() {
  return  (
    <div>

<section class=" bg-sky-800 overflow-x-hidden" style={{fontFamily:"open sans"}}>
 
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap -mx-4 mb-10 md:mb-0">
        <div class="w-full md:w-2/6   px-10">
          <div class="max-w-xs mx-auto pt-8">
            <a class="inline-block text-xl text-white font-medium font-heading mb-8 md:mb-38" href="#">
              <img class="h-12 w-28" src={FooterLogo} alt=""/>
            </a>
            <p class="max-w-xs text-white uppercase font-heading font-medium">Arambh is dedicated to make home ownership easier, faster & Cheaper for all Indians.</p>
          </div>
        </div>
        <div class="w-full md:w-4/6 px-4">
          <div class="md:py-20 px-8 lg:pl-20 lg:pr-10">
            <div class="flex flex-wrap -mx-4">
              <div class="w-screen md:w-1/4 px-2 mb-12 md:mb-0">
                <h3 class="mb-4 mt-8 md:mb-8 md:mt-2 text-sm text-white font-bold uppercase font-heading">Value Added Services</h3>
                <ul>
                  <li class=" mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/loan-calculator">Loan Calculator</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/emi-calculator">EMI Calculator</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/tca-calculator">Tenure Calculator</a></li>
                </ul>
              </div>
              <div class="w-screen md:w-1/4 px-4 mb-12 md:mb-0">
              <h3 class="mb-4 mt-2 md:mb-8 text-sm text-white font-bold uppercase font-heading">About Us</h3>
                <ul>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/about-us/why-arambh">Why Arambh</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light" href="https://arambh.co/about-us/customer-care-philosophy">Customer Care Philosophy</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/about-us/technology-based-approach">Technology Based Approach</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/about-us/our-team">Our Team </a></li>
                </ul>
              </div>
              <div class="w-screen md:w-1/4 px-4">
              <h3 class="mb-4 mt-2 md:mb-8 text-sm text-white font-bold uppercase font-heading">Home Loan Services</h3>
                <ul>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/loans/home-loan">Home Loan</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light" href="https://arambh.co/loans/transfer-existing-home-loan">Transfer Existing Home Loan</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/loans/personal-loan-against-property">Personal Loan against property</a></li>
                  <li class="mb-2 md:mb-6"><a class="text-white font-light " href="https://arambh.co/loans/loan-for-senior-citizens">Loan for Senior Citizens</a></li>
                </ul>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  
  {/* <div class="border-t py-16">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-between">
        <p class="mb-8 md:mb-0 text-gray-500">All rights reserved &copy; BoldUI 2021</p>
        <div class="flex w-full md:w-auto">
          <a class="mr-8" href="#">
            <img src="boldui-assets/brands/small-logos/facebook.svg" alt="">
          </a>
          <a class="mr-8" href="#">
            <img src="boldui-assets/brands/small-logos/twitter.svg" alt="">
          </a>
          <a class="mr-8" href="#">
            <img src="boldui-assets/brands/small-logos/instagram.svg" alt="">
          </a>
          <a class="mr-8" href="#">
            <img src="boldui-assets/brands/small-logos/github.svg" alt="">
          </a>
          <a href="#">
            <img src="boldui-assets/brands/small-logos/linkedin.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div> */}
</section>


    </div>
  )
}
