import React from 'react'

import PartnerImg19 from '../../static/images/axislogo.png'
import PartnerImg20 from '../../static/images/idfclogo.png'
import PartnerImg21 from '../../static/images/kotaklogo.png'
import PartnerImg22 from '../../static/images/liclogo.png'
import PartnerImg24 from '../../static/images/SBIlogo.png'
import PartnerImg25 from '../../static/images/SClogo.png'
import PartnerImg26 from '../../static/images/pnb.png'
import PartnerImg27 from '../../static/images/dhfllogo.png'


import Loadable from '@loadable/component';



    const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

    const options = {
        loop: false,
        nav: false,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        autoplayTimeout: 5000,
        responsive: {
            0:{
                items:1,
            },
            768:{
                items:4,
            },
            1200:{
                items:6,
            }
        }
    }
    
    const Partners = () => {
        const [display, setDisplay] = React.useState(false);
    
        React.useEffect(() => {
            setDisplay(true);
        }, [])
    

  return (



<div>
            <div className="container">
            <div>
                   
                </div>
                {display ? <OwlCarousel 
                    className=" mt-24 ml-8"
                    {...options}
                >
                    <div className=' m-4'>
                           <img src={PartnerImg19} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg20} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg21} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg22} alt="image" />
                     
                    </div>

                  

                    <div  className=' m-8'>
                           <img src={PartnerImg24} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg25} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg26} alt="image" />
                     
                    </div>

                    <div  className=' m-8'>
                           <img src={PartnerImg27} alt="image" />
                     
                    </div>
                 
                </OwlCarousel> : ''}
            </div>
        </div>
    );
}

export default Partners;
