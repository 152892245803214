

import React from 'react'
import Banner from '../../static/images/bang.png'
import BannerMob from '../../static/images/bangtwo.png'
// import Banner from '../../static/images/bannerone.png'
// import BannerMob from '../../static/images/bannertwo.png'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

export default function FormTwo() {

    const [visible, setVisible] = useState(false);
    const {
          register,
          handleSubmit,
          formState: { errors },
        } = useForm();
  
  const onSubmit = async (data) => {
          console.log(data)
          const response = await axios.post('https://nodeapi.arambh.co/api/leads/add', data);
        
          console.log({response})
          setVisible(true);
        }
  

        if (visible) {
            return <div >
                                      <div>
                                          <form>
                                              <h2>Thanks for your enquiry,
                                                  We will call you back Shortly !</h2>
                                                  <div>
                                                  <a href="https://arambh.co/get-started" target="_blank">Explore More</a>
                                                  </div>
      
      
                                          
                                          </form>
                                      </div>
                                  </div>
      
        }



    
  return (
    <div className="relative ">
      <img
        src={Banner}
        className="absolute inset-0 hidden md:block"
        alt=""
      />
      <img
        src={BannerMob}
        className="relative inset-0 block md:hidden"
        alt=""
      />
      <div className="relative h-screen bg-gray-900 bg-opacity-5">
        <div className="px-4 py-4 md:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-16 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between xl:flex-row">
            <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
              
            </div>
            <div className="w-full max-w-xl md:pl-10 xl:px-8 md:w-11/12 xl:w-11/12">
              <div className="bg-white rounded shadow-2xl p-7 sm:p-42">
                <h3 className="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                Please share your details to get a call from our Loan Experts 
                </h3>
                <form>

                  <div className=" flex flex-col md:flex-row mb-1 sm:mb-2">
                  <label className="block mb-4 mr-6">
                  <div class="relative">
            <input  {...register("name", { required: true })} className="px-4 py-3.5 w-full text-black font-medium placeholder-text-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-400" id="signInInput1-1" type="text" placeholder="Name"/>
            {errors.name?.type === 'required' && "Name is required"}
            </div>
          </label>

          <label className="block mb-4">
          <div class="relative">
            <input  {...register('email', { required: true })}  className="px-4 py-3.5 w-full text-black font-medium placeholder-text-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-400" id="signInInput1-2" type="text" placeholder="Email"/>
            {errors.email?.type === 'required' && "Email is required"}
            </div>
          </label>
                   
                  </div>

                  

                  <div className=" flex flex-col md:flex-row  mb-1 sm:mb-2">
                  <label className="block mb-4 mr-6">
            {/* <p class="mb-2 text-gray-900 font-semibold leading-normal">Name *</p> */}
            <input  {...register('phone_number', { required: true })}class="px-4 py-3.5 w-full text-black font-medium placeholder-text-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-400" id="signInInput1-1" type="text" placeholder="Phone"/>
            {errors.phone_number?.type === 'required' && "Phone is required"}
          </label>

          <label className="block mb-4">
            {/* <p class="mb-2 text-gray-900 font-semibold leading-normal">Name *</p> */}
            <select {...register('loan_type', { required: true })} class="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-text-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-400" id="signInInput1-1" type="text" placeholder="">
            <option >Home Loan</option>
            <option>Transfer existing Home Loan</option>
            <option>Personal Loan against property</option>
            <option>Loan for Senior citizens</option>
        </select>         
        {errors.loan_type?.type === 'required' && "loan_type is required"}
         </label>
                   
                  </div>

                  <div className="mb-1 sm:mb-2">
                
                   
                  </div>
                 
                  
               
                 
                  <div className="mt-4 mb-2 sm:mb-4">
                  <button className="mb-5 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-sky-700 hover:bg-indigo-800 transition ease-in-out duration-200" type="button" value="Send" >SCHEDULE NOW</button>

                  </div>
                  <div>
                  <div>
    
    <p className="text-xs text-gray-600 sm:text-sm mr-2 ">
    <input className='mr-2' type="checkbox"  name="interest" value="checked"/>I declare that the information I have provided is accurate & complete to the best of my knowledge. I hereby authorize the affilliates to call, email, send a text throught the Short Messaging Service (SMS) and/or whatsapp me in relation to any of their products.The consent herein shall override any registration for DNC/NDNC.
                  </p>
  </div>
                  
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};